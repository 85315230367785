import React from "react";
import "../../styles/SearchByCountry.scss";
import Select from "@material-ui/core/Select";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import "../../styles/Home.scss";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(0),
        minWidth: 250,
        marginRight: 10,
        maxWidth: 250,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));
const StyledFormControl = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "#0029FA",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#0029FA",
            },

            "&.Mui-focused fieldset": {
                borderColor: "#0029FA",
            },
        },
    },
})(FormControl);
const SelectedYear = ({ handleChangeCallback, list, val, disabled }) => {
    const classes = useStyles();

    const handleChange = (event) => {
        handleChangeCallback(event.target.value);
    };

    return (
        <>
            <StyledFormControl
                variant="outlined"
                className={classes.formControl}
                disabled={disabled === 0}
            >
                <InputLabel>Years</InputLabel>
                <Select
                    labelId="category-label"
                    id="category"
                    value={val}
                    onChange={handleChange}
                    label="Country"
                    native
                >
                    <option className="select-category" value=""></option>
                    {list === undefined || list.length === 0 ? (
                        <option className="select-category" value=""></option>
                    ) : (
                        list.map((element, index) => (
                            <option
                                className="select-category"
                                key={index}
                                value={element.yearfrom + "-" + element.yearto}
                            >
                                {element.yearfrom + "-" + element.yearto}
                            </option>
                        ))
                    )}
                </Select>
            </StyledFormControl>
        </>
    );
};
export default SelectedYear;
