import React from "react";
import SportsBasketballIcon from "@material-ui/icons/SportsBasketball";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import { ValidateUrl, ValidateTableTextInput } from "../../validation";
import "../../styles/PlayerCard.scss";
import "../../styles/TableStat.scss";

import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

const theme = createMuiTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: "12px",
                color: "#000",
                backgroundColor: "#fdfdfd28",
            },
        },
    },
});

const Validate = (val) => {
    if (
        val === null ||
        val === -1 ||
        val === "-1" ||
        val === undefined ||
        val === ""
    ) {
        return <div>--</div>;
    } else {
        return val.toFixed(1);
    }
};

const PlayerCareerTable = (props) => {
    const { playerStats, gender, handleSeason } = props;

    const handleSeasonClick = (filed) => {
        handleSeason(filed);
    };

    return (
        <>
            <MuiThemeProvider theme={theme}>
                {playerStats === undefined ? (
                    <LinearProgress style={{height:10}} color="secondary"></LinearProgress>
                ) : (
                    <div style={{ width: "100%" }}>
                        <table className="table-stat">
                            <thead className="career-head">
                                <tr>
                                    <th
                                        style={{
                                            paddingLeft: 10,
                                            textAlign: "center",
                                            cursor: "pointer",
                                        }}
                                        onClick={() =>
                                            handleSeasonClick("yearto")
                                        }
                                    >
                                        Season
                                    </th>
                                    <th
                                        style={{
                                            textAlign: "center",
                                        }}
                                        onClick={() =>
                                            handleSeasonClick("country")
                                        }
                                    >
                                        Country
                                    </th>
                                    <th
                                        style={{
                                            textAlign: "center",
                                        }}
                                        onClick={() =>
                                            handleSeasonClick("team")
                                        }
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <SportsBasketballIcon
                                                style={{ marginRight: 3 }}
                                            ></SportsBasketballIcon>
                                            Team
                                        </div>
                                    </th>
                                    <th
                                        style={{
                                            textAlign: "center",
                                        }}
                                        onClick={() =>
                                            handleSeasonClick("comment")
                                        }
                                    >
                                        League
                                    </th>
                                    <Tooltip title="Games" placement="top">
                                        <th
                                            align="center"
                                            onClick={() =>
                                                handleSeasonClick("gm")
                                            }
                                        >
                                            Gm
                                        </th>
                                    </Tooltip>
                                    <Tooltip title="Minutes" placement="top">
                                        <th
                                            align="center"
                                            style={{
                                                backgroundColor: "#FFC2FF",
                                            }}
                                            onClick={() =>
                                                handleSeasonClick("min")
                                            }
                                        >
                                            Min
                                        </th>
                                    </Tooltip>
                                    <Tooltip title="Points" placement="top">
                                        <th
                                            align="center"
                                            style={{ background: "#FFDB99" }}
                                            onClick={() =>
                                                handleSeasonClick("pts")
                                            }
                                        >
                                            Pts
                                        </th>
                                    </Tooltip>
                                    <Tooltip
                                        title="1 Point Made"
                                        placement="top"
                                    >
                                        <th
                                            align="center"
                                            onClick={() =>
                                                handleSeasonClick("1PM")
                                            }
                                        >
                                            1PM
                                        </th>
                                    </Tooltip>
                                    <Tooltip
                                        title="1 Point Attempt"
                                        placement="top"
                                    >
                                        <th
                                            align="center"
                                            onClick={() =>
                                                handleSeasonClick("1PA")
                                            }
                                        >
                                            1PA
                                        </th>
                                    </Tooltip>
                                    <Tooltip
                                        title="1 Point Percentage"
                                        placement="top"
                                    >
                                        <th
                                            align="center"
                                            style={{
                                                backgroundColor: "#FFFF99",
                                            }}
                                            onClick={() =>
                                                handleSeasonClick("1P%")
                                            }
                                        >
                                            1P%
                                        </th>
                                    </Tooltip>
                                    <Tooltip
                                        title="2 Point Made"
                                        placement="top"
                                    >
                                        <th
                                            align="center"
                                            onClick={() =>
                                                handleSeasonClick("2PM")
                                            }
                                        >
                                            2PM
                                        </th>
                                    </Tooltip>
                                    <Tooltip
                                        title="2 Point Attempt"
                                        placement="top"
                                    >
                                        <th
                                            align="center"
                                            onClick={() =>
                                                handleSeasonClick("2PA")
                                            }
                                        >
                                            2PA
                                        </th>
                                    </Tooltip>
                                    <Tooltip
                                        title="2 Point Percentage"
                                        placement="top"
                                    >
                                        <th
                                            align="center"
                                            style={{ background: "#99FFCC" }}
                                            onClick={() =>
                                                handleSeasonClick("2P%")
                                            }
                                        >
                                            2P%
                                        </th>
                                    </Tooltip>
                                    <Tooltip
                                        title="3 Point Made"
                                        placement="top"
                                    >
                                        <th
                                            align="center"
                                            onClick={() =>
                                                handleSeasonClick("3PM")
                                            }
                                        >
                                            3PM
                                        </th>
                                    </Tooltip>
                                    <Tooltip
                                        title="3 Point Attempt"
                                        placement="top"
                                    >
                                        <th
                                            align="center"
                                            onClick={() =>
                                                handleSeasonClick("3PA")
                                            }
                                        >
                                            3PA
                                        </th>
                                    </Tooltip>
                                    <Tooltip
                                        title="3 Point Percentage"
                                        placement="top"
                                    >
                                        <th
                                            align="center"
                                            style={{ background: "#9FFFFF" }}
                                            onClick={() =>
                                                handleSeasonClick("3P%")
                                            }
                                        >
                                            3P%
                                        </th>
                                    </Tooltip>
                                    <Tooltip
                                        title="Rebounds - Defense"
                                        placement="top"
                                    >
                                        <th
                                            align="center"
                                            onClick={() =>
                                                handleSeasonClick("rd")
                                            }
                                        >
                                            RD
                                        </th>
                                    </Tooltip>
                                    <Tooltip
                                        title="Rebounds - Offense"
                                        placement="top"
                                    >
                                        <th
                                            align="center"
                                            onClick={() =>
                                                handleSeasonClick("ro")
                                            }
                                        >
                                            RO
                                        </th>
                                    </Tooltip>
                                    <Tooltip
                                        title="Rebounds - Total"
                                        placement="top"
                                    >
                                        <th
                                            align="center"
                                            style={{ background: "#93CCFF" }}
                                            onClick={() =>
                                                handleSeasonClick("rt")
                                            }
                                        >
                                            RT
                                        </th>
                                    </Tooltip>

                                    <Tooltip title="Assists" placement="top">
                                        <th
                                            align="center"
                                            onClick={() =>
                                                handleSeasonClick("ast")
                                            }
                                        >
                                            Ast
                                        </th>
                                    </Tooltip>
                                    <Tooltip title="Turn Overs" placement="top">
                                        <th
                                            align="center"
                                            style={{ background: "#CCB5FF" }}
                                            onClick={() =>
                                                handleSeasonClick("TO")
                                            }
                                        >
                                            To
                                        </th>
                                    </Tooltip>
                                    <Tooltip title="Steals" placement="top">
                                        <th
                                            align="center"
                                            onClick={() =>
                                                handleSeasonClick("st")
                                            }
                                        >
                                            St
                                        </th>
                                    </Tooltip>
                                    <Tooltip
                                        title="Block Shots Made"
                                        placement="top"
                                    >
                                        <th
                                            align="center"
                                            onClick={() =>
                                                handleSeasonClick("bl")
                                            }
                                        >
                                            BL
                                        </th>
                                    </Tooltip>
                                    <Tooltip
                                        title="Block Shots on Player"
                                        placement="top"
                                    >
                                        <th
                                            align="center"
                                            onClick={() =>
                                                handleSeasonClick("bo")
                                            }
                                        >
                                            BO
                                        </th>
                                    </Tooltip>
                                    <Tooltip
                                        title="Personal Foul"
                                        placement="top"
                                    >
                                        <th
                                            align="center"
                                            onClick={() =>
                                                handleSeasonClick("pf")
                                            }
                                        >
                                            PF
                                        </th>
                                    </Tooltip>
                                    <Tooltip
                                        title="Foul on Player"
                                        placement="top"
                                    >
                                        <th
                                            align="center"
                                            onClick={() =>
                                                handleSeasonClick("fo")
                                            }
                                        >
                                            FO
                                        </th>
                                    </Tooltip>
                                    <Tooltip title="Dunk" placement="top">
                                        <th
                                            align="center"
                                            onClick={() =>
                                                handleSeasonClick("du")
                                            }
                                        >
                                            DU
                                        </th>
                                    </Tooltip>
                                    <Tooltip
                                        title="Highest Score During Season"
                                        placement="top"
                                    >
                                        <th
                                            align="center"
                                            onClick={() =>
                                                handleSeasonClick("hi")
                                            }
                                        >
                                            Hi
                                        </th>
                                    </Tooltip>
                                    <Tooltip
                                        title="Number on Shirt-Jersey"
                                        placement="top"
                                    >
                                        <th
                                            align="center"
                                            onClick={() =>
                                                handleSeasonClick("ns")
                                            }
                                        >
                                            Ns
                                        </th>
                                    </Tooltip>
                                </tr>
                            </thead>
                            <tbody className="career-body">
                                {playerStats.map((row, index) => (
                                    <tr
                                        key={index}
                                        className={
                                            row.isChanged
                                                ? "changed"
                                                : "not-changed"
                                        }
                                    >
                                        <td
                                            component="th"
                                            style={{
                                                textAlign: "center",
                                            }}
                                        >
                                            {row.yearfrom}-
                                            {String(row.yearto).substr(2)}
                                        </td>
                                        <td
                                            style={{
                                                textAlign: "left",
                                            }}
                                        >
                                            {row.country}
                                        </td>

                                        <td
                                            style={{
                                                textAlign: "left",
                                                color: "blue",
                                                textDecoration: "undrline",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <Link
                                                to={`/teams/${gender}/${ValidateUrl(
                                                    row.country
                                                )}/${ValidateUrl(row.team)}/${
                                                    row.yearfrom
                                                }-${row.yearto}/${ValidateUrl(
                                                    row.comment
                                                )}/`}
                                            >
                                                {row.team}
                                            </Link>
                                        </td>
                                        <td
                                            style={{
                                                textAlign: "left",
                                            }}
                                        >
                                            {ValidateTableTextInput(
                                                row.comment
                                            )}
                                        </td>
                                        <Tooltip title="Games" placement="top">
                                            <td align="right">{row.gm}</td>
                                        </Tooltip>
                                        <Tooltip
                                            title="Minutes"
                                            placement="top"
                                        >
                                            <td
                                                align="right"
                                                style={{
                                                    backgroundColor: "#FFC2FF",
                                                }}
                                            >
                                                {Validate(row.min)}
                                            </td>
                                        </Tooltip>
                                        <Tooltip title="Points" placement="top">
                                            <td
                                                align="right"
                                                style={{
                                                    backgroundColor: "#FFDB99",
                                                }}
                                            >
                                                {Validate(row.pts)}
                                            </td>
                                        </Tooltip>

                                        <Tooltip
                                            title="1 Point Made"
                                            placement="top"
                                        >
                                            <td align="right">
                                                {Validate(row["1PM"])}
                                            </td>
                                        </Tooltip>
                                        <Tooltip
                                            title="1 Point Attempt"
                                            placement="top"
                                        >
                                            <td align="right">
                                                {Validate(row["1PA"])}
                                            </td>
                                        </Tooltip>
                                        <Tooltip
                                            title="1 Point Percentage"
                                            placement="top"
                                        >
                                            <td
                                                align="right"
                                                style={{
                                                    backgroundColor: "#FFFF99",
                                                }}
                                            >
                                                {Validate(row["1P%"])}
                                            </td>
                                        </Tooltip>
                                        <Tooltip
                                            title="2 Point Made"
                                            placement="top"
                                        >
                                            <td align="right">
                                                {Validate(row["2PM"])}
                                            </td>
                                        </Tooltip>
                                        <Tooltip
                                            title="2 Point Attempt"
                                            placement="top"
                                        >
                                            <td align="right">
                                                {Validate(row["2PA"])}
                                            </td>
                                        </Tooltip>
                                        <Tooltip
                                            title="2 Point Percentage"
                                            placement="top"
                                        >
                                            <td
                                                align="right"
                                                style={{
                                                    backgroundColor: "#99FFCC",
                                                }}
                                            >
                                                {Validate(row["2P%"])}
                                            </td>
                                        </Tooltip>
                                        <Tooltip
                                            title="3 Point Made"
                                            placement="top"
                                        >
                                            <td align="right">
                                                {Validate(row["3PM"])}
                                            </td>
                                        </Tooltip>
                                        <Tooltip
                                            title="3 Point Attempt"
                                            placement="top"
                                        >
                                            <td align="right">
                                                {Validate(row["3PA"])}
                                            </td>
                                        </Tooltip>
                                        <Tooltip
                                            title="3 Point Percentage"
                                            placement="top"
                                        >
                                            <td
                                                align="right"
                                                style={{
                                                    backgroundColor: "#9FFFFF",
                                                }}
                                            >
                                                {Validate(row["3P%"])}
                                            </td>
                                        </Tooltip>
                                        <Tooltip
                                            title="Rebounds - Defense"
                                            placement="top"
                                        >
                                            <td align="right">
                                                {Validate(row.rd)}
                                            </td>
                                        </Tooltip>
                                        <Tooltip
                                            title="Rebounds - Offense"
                                            placement="top"
                                        >
                                            <td align="right">
                                                {Validate(row.ro)}
                                            </td>
                                        </Tooltip>
                                        <Tooltip
                                            title="Rebounds - Total"
                                            placement="top"
                                        >
                                            <td
                                                align="right"
                                                style={{
                                                    backgroundColor: "#93CCFF",
                                                }}
                                            >
                                                {Validate(row.rt)}
                                            </td>
                                        </Tooltip>
                                        <Tooltip
                                            title="Assists"
                                            placement="top"
                                        >
                                            <td align="right">
                                                {Validate(row.ast)}
                                            </td>
                                        </Tooltip>
                                        <Tooltip
                                            title="Turn Overs"
                                            placement="top"
                                            style={{
                                                backgroundColor: "#CCB5FF",
                                            }}
                                        >
                                            <td align="right">
                                                {Validate(row.TO)}
                                            </td>
                                        </Tooltip>
                                        <Tooltip title="Steals" placement="top">
                                            <td align="right">
                                                {Validate(row.st)}
                                            </td>
                                        </Tooltip>
                                        <Tooltip
                                            title="Block Shots Made"
                                            placement="top"
                                        >
                                            <td align="right">
                                                {Validate(row.bl)}
                                            </td>
                                        </Tooltip>
                                        <Tooltip
                                            title="Block Shots on Player"
                                            placement="top"
                                        >
                                            <td align="right">
                                                {Validate(row.bo)}
                                            </td>
                                        </Tooltip>
                                        <Tooltip
                                            title="SeaPersonal Foulson"
                                            placement="top"
                                        >
                                            <td align="right">
                                                {Validate(row.pf)}
                                            </td>
                                        </Tooltip>
                                        <Tooltip
                                            title="Foul on Player"
                                            placement="top"
                                        >
                                            <td align="right">
                                                {Validate(row.fo)}
                                            </td>
                                        </Tooltip>
                                        <Tooltip title="Dunk" placement="top">
                                            <td align="right">
                                                {Validate(row.du)}
                                            </td>
                                        </Tooltip>
                                        <Tooltip
                                            title="Highest Score During Season"
                                            placement="top"
                                        >
                                            <td align="right">
                                                {row.hi === null ||
                                                row.hi === -1 ||
                                                row.hi === "-1" ||
                                                row.hi === undefined ||
                                                row.hi === ""
                                                    ? "--"
                                                    : row.hi.toFixed(0)}
                                            </td>
                                        </Tooltip>
                                        <Tooltip
                                            title="Number on Shirt-Jersey"
                                            placement="top"
                                        >
                                            <td align="right">
                                                {row.ns === null ||
                                                row.ns === -1 ||
                                                row.ns === "-1" ||
                                                row.ns === undefined ||
                                                row.ns === ""
                                                    ? "--"
                                                    : row.ns.toFixed(0)}
                                            </td>
                                        </Tooltip>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </MuiThemeProvider>
        </>
    );
};
export default PlayerCareerTable;
