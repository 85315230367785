import React from "react";
import Page from "../assetes/page.png";
const PageDoesntExist = () => {
    return (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: 50,
                }}
            >
                <img src={Page} alt="404" />
            </div>
        </>
    );
};
export default PageDoesntExist;
