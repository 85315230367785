import React from "react";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core";

import { setGender } from "../../actions";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
    return {
        gender: state.gender,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleChange: (event) => dispatch(setGender(event.target.value)),
    };
};

const useStyles = makeStyles(() => ({
    radio_group: {
        display: "flex",
        flexDirection: "row",
    },
    radio_button: {
        fontSize: "calc(10px + 2vmin)",
    },
}));

const GenderSelect = (props) => {
    const { gender, handleChange } = props;
    const classes = useStyles();

    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">Select Gender</FormLabel>
            <RadioGroup
                aria-label="Select Gender"
                name="gender"
                value={gender}
                onChange={handleChange}
                className={classes.radio_group}
            >
                <FormControlLabel
                    className={classes.radio_button}
                    value="male"
                    control={<Radio />}
                    label="Men Statistics"
                />
                <FormControlLabel
                    className={classes.radio_button}
                    value="female"
                    control={<Radio />}
                    label="Women Statistics"
                />
            </RadioGroup>
        </FormControl>
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(GenderSelect);
