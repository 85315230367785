import React from "react";
import TextField from "@material-ui/core/TextField";
import "../../styles/SearchByPlayerName.scss";
import InputAdornment from "@material-ui/core/InputAdornment";
import PersonIcon from "@material-ui/icons/Person";
import { Link } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { ValidateUrl } from "../../validation";

const mapStateToProps = (state) => {
    return {
        gender: state.gender,
    };
};

const useStyles = makeStyles((theme) => ({
    btn: {
        color: "#fff",
        textDecoration: "none",
        marginTop: 10,
        "&:hover": {
            color: "#61dafb",
        },
    },
}));

const StyledTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "#0029FA",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#0029FA",
            },
            "&:hover fieldset": {
                borderColor: " #F2913D",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#0029FA",
            },
        },
    },
})(TextField);

const SearchByPlayerNameForRes = (props) => {
    const [val, setVal] = React.useState("");
    const [hasError, setHasError] = React.useState(false);
    const history = useHistory();

    const classes = useStyles();

    const handleChange = (event) => {
        if (event.target.value.length > 2 || event.target.value.length === 0) {
            setHasError(false);
        } else {
            setHasError(true);
        }
        setVal(event.target.value);
    };

    const keyPress = (event) => {
        if (event.keyCode === 13) {
            if (
                event.target.value.length > 2 ||
                event.target.value.length === 0
            ) {
                setHasError(false);
                history.push({
                    pathname: `/player-search/${gender}/${ValidateUrl(
                        event.target.value
                    )}`,
                });
            } else {
                setHasError(true);
            }
        }
    };

    const { gender } = props;
    return (
        <div className="search-by-name-res">
            <div className="search-Filed">
                <div>
                    <div className="search-input">
                        <StyledTextField
                            id="outlined-basic"
                            label="New Search"
                            variant="outlined"
                            value={val}
                            onChange={handleChange}
                            onKeyDown={keyPress}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PersonIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div>
                        {hasError && (
                            <span
                                style={{
                                    color: "red",
                                    fontSize: 16,
                                    marginLeft: 0,
                                    marginTop: 4,
                                }}
                            >
                                *Input must contain at least 3 characters
                            </span>
                        )}
                    </div>
                </div>
            </div>

            <div>
                {!val || hasError ? (
                    <Link
                        to="/"
                        onClick={(event) => event.preventDefault()}
                        style={{ textDecoration: "none" }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            style={{
                                marginTop: 15,
                                marginLeft: 10,
                            }}
                            disabled={true}
                        >
                            <SearchIcon></SearchIcon>Search
                        </Button>
                    </Link>
                ) : (
                    <Link
                        to={`/player-search/${gender}/${ValidateUrl(val)}`}
                        className={classes.btn}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginTop: 15, marginLeft: 10 }}
                        >
                            <SearchIcon></SearchIcon>Search
                        </Button>
                    </Link>
                )}
            </div>
        </div>
    );
};
export default connect(mapStateToProps)(SearchByPlayerNameForRes);
