import React from "react";
import "../../styles/SearchByCountry.scss";
import SelectCategory from "./SelectCategory";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import SelectedYear from "./SelectedYear";
import LinearProgress from "@material-ui/core/LinearProgress";
import { API_END_POINT, ValidateUrl } from "../../validation";

const mapStateToProps = (state) => {
    return {
        gender: state.gender,
    };
};

class SearchByCountry extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleTeamChange = this.handleTeamChange.bind(this);
        this.handleYearChange = this.handleYearChange.bind(this);
        this.handleLeagueChange = this.handleLeagueChange.bind(this);

        this.state = {
            country: "",
            team: "",
            year: "",
            league: "",
            countries: [],
            teams: [],
            years: [],
            leagues: [],
            duplicates: [],
            countryExtra: "",
            validTeam: "",
            validLeage: "",
            dataReceived: false,
        };
    }

    componentDidMount() {
        if (!this._isMounted) {
            this._isMounted = true;

            let gen = "";

            if (this.props.gender !== "male") {
                gen = "w";
            }

            fetch(API_END_POINT + `getAllCountries${gen}`)
                .then((res) => {
                    return res.json();
                })
                .then((countries) => {
                    let res = [];
                    let dup = [];

                    for (let i = 0; i < countries.countries.length; ++i) {
                        if (/\s+$/.test(countries.countries[i])) {
                            dup.push(countries.countries[i]);
                            res.push(countries.countries[i]);
                        } else {
                            res.push(countries.countries[i]);
                        }
                    }

                    this.setState({
                        countries: res,
                        duplicates: dup,
                        dataReceived: true,
                    });
                })
                .catch((err) => console.log("error: " + err));
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleCountryChange = (country) => {
        if (this._isMounted) {
            let gen = "";

            if (this.props.gender !== "male") {
                gen = "w";
            }

            let extra = country.trim();

            for (let i = 0; i < this.state.duplicates.length; ++i) {
                if (this.state.duplicates[i].includes(country)) {
                    extra += "%20";
                    break;
                }
            }

            this.setState({
                country: country,
                teams: [],
                years: [],
                leagues: [],
                team: "",
                year: "",
                league: "",
                countryExtra: extra,
                validTeam: "",
                validLeage: "",
            });

            fetch(API_END_POINT + `getTeamsByCountry${gen}?country=${extra}`)
                .then((res) => {
                    return res.json();
                })
                .then((teams) => {
                    this.setState({ teams: teams.teams.sort() });
                })
                .catch((err) => console.log("error: " + err));
        }
    };

    handleTeamChange = (team) => {
        if (this._isMounted) {
            let valid = ValidateUrl(team);

            this.setState({
                team: team,
                years: [],
                leagues: [],
                league: "",
                year: "",
                validTeam: valid,
                validLeage: "",
            });

            let gen = "";

            if (this.props.gender !== "male") {
                gen = "w";
            }

            fetch(
                API_END_POINT +
                    `getYearsByCountryAndTeam${gen}?country=${this.state.countryExtra}&team=${valid}`
            )
                .then((res) => {
                    return res.json();
                })
                .then((years) => {
                    this.setState({ years: years.years });
                })
                .catch((err) => console.log("error: " + err));
        }
    };

    handleYearChange = (year) => {
        if (this._isMounted) {
            if (year !== "") {
                this.setState({
                    year: year,
                    leagues: [],
                    league: "",
                    validLeage: "",
                });
                let fromTo = year.split(/[\s-]+/);

                let gen = "";

                if (this.props.gender !== "male") {
                    gen = "w";
                }

                fetch(
                    API_END_POINT +
                        `getLeagues${gen}?country=${this.state.countryExtra}&team=${this.state.team}&yearfrom=${fromTo[0]}&yearto=${fromTo[1]}`
                )
                    .then((res) => {
                        return res.json();
                    })
                    .then((leagues) => {
                        this.setState({ leagues: leagues.leagues });
                    })
                    .catch((err) => console.log("error: " + err));
            } else {
                this.setState({
                    year: year,
                    leagues: [],
                    league: "",
                    validLeage: "",
                });
            }
        }
    };

    handleLeagueChange = (league) => {
        if (this._isMounted) {
            let valid = ValidateUrl(league);

            this.setState({
                league: league,
                validLeage: valid,
            });
        }
    };

    componentDidUpdate(prevProps) {
        if (this._isMounted) {
            if (prevProps.gender !== this.props.gender) {
                let gen = "";

                if (this.props.gender !== "male") {
                    gen = "w";
                }

                fetch(API_END_POINT + `getAllCountries${gen}`)
                    .then((res) => {
                        return res.json();
                    })
                    .then((countries) => {
                        let res = [];
                        let dup = [];
                        res.push(countries.countries[0]);

                        for (let i = 1; i < countries.countries.length; ++i) {
                            if (countries.countries[i] === res[i - 1]) {
                                dup.push(countries.countries[i]);
                            } else {
                                res.push(countries.countries[i]);
                            }
                        }

                        this.setState({
                            countries: res,
                            country: "",
                            team: "",
                            year: "",
                            league: "",
                            teams: [],
                            years: [],
                            leagues: [],
                            duplicates: dup,
                            validTeam: "",
                            validLeage: "",
                        });
                    });
            }
        }
    }

    render() {
        const { gender } = this.props;

        return (
            <>
                {this.state.dataReceived === false ? (
                    <LinearProgress
                        style={{ height: 10 }}
                        color="secondary"
                    ></LinearProgress>
                ) : (
                    <div>
                        <div className="search-by-country">
                            <div className="search-label">
                                Team Search By Country
                            </div>

                            <div className="select-categories">
                                <div className="select-category">
                                    <SelectCategory
                                        name="Country"
                                        handleChangeCallback={
                                            this.handleCountryChange
                                        }
                                        list={this.state.countries}
                                        val={this.state.country}
                                    ></SelectCategory>
                                </div>
                                <div className="select-category">
                                    <SelectCategory
                                        disabled={this.state.teams.length}
                                        name="Team"
                                        handleChangeCallback={
                                            this.handleTeamChange
                                        }
                                        list={this.state.teams}
                                        val={this.state.team}
                                    ></SelectCategory>
                                </div>
                                <div className="select-category">
                                    <SelectedYear
                                        disabled={this.state.years.length}
                                        handleChangeCallback={
                                            this.handleYearChange
                                        }
                                        list={this.state.years}
                                        val={this.state.year}
                                    ></SelectedYear>
                                </div>
                                <div className="select-category">
                                    <SelectCategory
                                        disabled={this.state.leagues.length}
                                        name="League"
                                        handleChangeCallback={
                                            this.handleLeagueChange
                                        }
                                        list={this.state.leagues}
                                        val={this.state.league}
                                    ></SelectCategory>
                                </div>
                            </div>
                        </div>

                        <div className="search-button">
                            {!this.state.league ? (
                                <Link
                                    to="/"
                                    onClick={(event) => event.preventDefault()}
                                ></Link>
                            ) : (
                                <Link
                                    target="_blank"
                                    to={`/teams/${gender}/${this.state.countryExtra}/${this.state.validTeam}/${this.state.year}/${this.state.validLeage}/`}
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button variant="contained" color="primary">
                                        <SearchIcon></SearchIcon>Search
                                    </Button>
                                </Link>
                            )}
                        </div>
                    </div>
                )}
            </>
        );
    }
}
export default connect(mapStateToProps)(withRouter(SearchByCountry));
