import React from "react";

export const API_END_POINT = "http://server.basketballstat.info/api/";

export function ValidateTableNumericInput(val) {
    if (
        val === null ||
        val === -1 ||
        val === "-1" ||
        val === undefined ||
        val === ""
    ) {
        return <div>--</div>;
    } else {
        return val.toFixed(1);
    }
}

export function ValidateTableTextInput(val) {
    if (
        val === null ||
        val === -1 ||
        val === "-1" ||
        val === undefined ||
        val === "" ||
        val === 0
    ) {
        return <div>--</div>;
    } else {
        return val;
    }
}

export function FetchData(url) {
    const resp = fetch(API_END_POINT + url)
        .then((res) => {
            return res.json();
        })
        .then((data) => {
            return data;
        })
        .catch((err) => {
            console.log(err);
            return undefined;
        });
    return resp;
}

export function ValidateUrl(val) {
    if (val === null) {
        return "All";
    }
    let name = val;

    name = name.split("+").join("%2B");
    name = name.split("&").join("%26");
    name = name.split(",").join("%2C");
    name = name.split("/").join("%2F");
    name = name.split("\\").join("%27");

    return name;
}

export function ReturnToUnvalidateUrl(val) {
    let name = val;

    name = name.split("%2B").join("+");
    name = name.split("%26").join("&");
    name = name.split("%2C").join(",");
    name = name.split("%2F").join("/");
    name = name.split("%27").join("\\");

    return name;
}
