import React from "react";

import { withRouter } from "react-router-dom";

import PlayerSearchResultsTable from "./PlayerSearchResultsTable";
import LinearProgress from "@material-ui/core/LinearProgress";
import { FetchData } from "../../validation";

import "../../styles/PlayerSearchResults.scss";
import SearchByPlayerNameForRes from "../search_components/SearchByPlayerNameForRes";

class PlayerSearchResults extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            gender: this.props.match.params.gender,
            name: this.props.match.params.name,
            playersList: [],
            searchField: "",
            isDataReceived: false,
        };
    }

    async componentDidMount() {
        if (!this._isMounted) {
            this._isMounted = true;

            let gen = "";

            if (this.state.gender !== "male") {
                gen = "w";
            }

            const ret_val = await FetchData(
                `getPlayers${gen}?player=${this.state.name}`
            );

            ret_val.players.sort((a, b) =>
                a.plname > b.plname ? 1 : b.plname > a.plname ? -1 : 0
            );

            this.setState({
                playersList: ret_val.players,
                isDataReceived: true,
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <>
                <div className="main-player-search">
                    <div className="player-search-results-table">
                        {this.state.playersList.length === 0 &&
                        this.state.isDataReceived === false ? (
                            <LinearProgress
                                style={{ height: 10 }}
                                color="secondary"
                            ></LinearProgress>
                        ) : (
                            <div>
                                <div style={{ display: "flex", alignItems: "end", gap: "20px", marginBottom: "10px" }}>
                                    <div style={{ margin: 5, fontSize: 20 }}>
                                        Found {this.state.playersList.length}{" "}
                                        matches for
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: 20,
                                                color: "red",
                                            }}
                                        >
                                            {" "}
                                            {this.state.name}
                                        </span>
                                        .
                                    </div>
                                    <SearchByPlayerNameForRes val={this.state.name} />
                                </div>
                                <PlayerSearchResultsTable
                                    selectedGender={this.state.gender}
                                    playersList={this.state.playersList}
                                    isDataReceived={this.state.isDataReceived}
                                ></PlayerSearchResultsTable>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
}
export default withRouter(PlayerSearchResults);
