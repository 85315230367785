import { CHANGE_GEDER } from "./constants";

const initialState = {
    gender: "male",
};

export const changeGender = (state = initialState, action = {}) => {
    switch (action.type) {
        case CHANGE_GEDER:
            return Object.assign({}, state, { gender: action.payload });
        //return { ...state, gender: action.payload };
        default:
            return state;
    }
};
