import React from "react";

import GenderSelect from "./search_components/GenderSelect";
import SearchTabControl from "./search_components/SearchTabControl";

import "../styles/Home.scss";
import Court from "../assetes/court.png";
import Ad from "./Ad";

const Home = () => {
    const [getGender, setGender] = React.useState("male");

    const getGenderSelection = (gender) => {
        setGender(gender);
    };

    return (
        <>
            <div className="home">
                {/* <div className="banner">
                    <h2 style={{ marginLeft: 15 }}>
                        The Most Comprehensive Basketball Database In The World{" "}
                    </h2>
                </div> */}
                {/* <Ad /> */}
                <div className="home-main">
                    <div className="get-statistics">
                        <h4
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            The Most Comprehensive Basketball Database In The
                            World
                        </h4>
                        <h5
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <img
                                src={Court}
                                alt="court"
                                style={{ height: 40, marginRight: 5 }}
                            />
                            Get Statistics
                        </h5>
                        <div className="gender-select">
                            <GenderSelect
                                genderCallback={getGenderSelection}
                            ></GenderSelect>
                        </div>
                        <div className="tab-controll">
                            <SearchTabControl
                                playerGender={getGender}
                                genderCallback={getGenderSelection}
                            ></SearchTabControl>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Home;
