import React from "react";
import PlayerTable from "./PlayerTable";
import { withRouter } from "react-router-dom";

import "../../styles/PlayerCard.scss";
import PlayerCareerTable from "./PlayerCareerTable";
import SearchByPlayerNameForRes from "../search_components/SearchByPlayerNameForRes";
import { API_END_POINT, ValidateUrl } from "../../validation";
import { LinearProgress } from "@material-ui/core";
import { Helmet } from "react-helmet";

function AddGroupToArray(arr) {
    let isChanged = false;

    arr[0].isChanged = isChanged;

    for (let i = 0; i < arr.length - 1; ++i) {
        let year = arr[i].yearfrom.toString() + arr[i].yearto.toString();
        let nextYear =
            arr[i + 1].yearfrom.toString() + arr[i + 1].yearto.toString();

        if (year !== nextYear) {
            isChanged = !isChanged;
            arr[i + 1].isChanged = isChanged;
        } else {
            arr[i + 1].isChanged = isChanged;

            if (arr[i].comment !== null && arr[i].comment !== undefined) {
                if (arr[i].comment.includes("PO")) {
                    let temp = arr[i];
                    arr[i] = arr[i + 1];
                    arr[i + 1] = temp;
                }
            }
        }
    }

    return arr;
}

function SortNotRSorPODown(arr) {
    for (let i = 0; i < arr.length - 1; ++i) {
        let year = arr[i].yearfrom.toString() + arr[i].yearto.toString();
        let nextYear =
            arr[i + 1].yearfrom.toString() + arr[i + 1].yearto.toString();

        if (year === nextYear) {
       
            if (arr[i].comment !== null && arr[i].comment !== undefined) {
                if (!arr[i].comment.includes("PO") && !arr[i].comment.includes("RS") ) {
                    let temp = arr[i];
                    arr[i] = arr[i + 1];
                    arr[i + 1] = temp;
                }      
            }
        }
    }
    return arr;
}

class PlayerCard extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.handleSeason = this.handleSeason.bind(this);

        this.state = {
            gender: this.props.match.params.gender,
            name: this.props.match.params.name,
            playerData: this.props.location.state,
            playerStats: [],
            isFromRoute: false,
            dataRecieved: false,
            isSeasonFlip: false,
        };
    }

    componentDidMount() {
        if(!this._isMounted)
        {
            this._isMounted = true;
            let gen = "";

            if (this.state.gender !== "male") {
                gen = "w";
            }

            fetch(API_END_POINT + `getPlayerStats${gen}?player=${this.state.name}`)
            .then((res) => {
                return res.json();
            })
            .then((player) => {
                if (player === undefined) {
                    return;
                }

                let teams_sort = player.playerStats.sort(function (a, b) {
                    if (
                        a.comment !== null &&
                        b.comment !== null &&
                        a.comment !== undefined &&
                        b.comment !== undefined
                    ) {
                        if (a.comment > b.comment) {
                            return -1;
                        }
                        if (b.comment > a.comment) {
                            return 1;
                        }
                    }
                    return 0;
                });

                let res = teams_sort.sort(function (a, b) {
                    if (
                        a.team !== null &&
                        b.team !== null &&
                        a.team !== undefined &&
                        b.team !== undefined
                    ) {
                        if (a.team > b.team) {
                            return -1;
                        }
                        if (b.team > a.team) {
                            return 1;
                        }
                    }
                    return 0;
                });

                res = res.sort((a, b) => a.yearto - b.yearto);
                res = res.sort((a, b) => a.yearfrom - b.yearfrom);
                res = AddGroupToArray(res);
                res = SortNotRSorPODown(res);
                
                res = res.sort(function (a, b) {
                    if (
                        a.team !== null &&
                        b.team !== null &&
                        a.team !== undefined &&
                        b.team !== undefined
                    ) {
                        if (a.team > b.team) {
                            return -1;
                        }
                        if (b.team > a.team) {
                            return 1;
                        }
                    }
                    return 0;
                });
                res = res.sort((a, b) => a.yearto - b.yearto);
                res = res.sort((a, b) => a.yearfrom - b.yearfrom);

                this.setState({
                    playerStats: res,
                    dataRecieved: true,
                });

                if (this.state.playerData === undefined) {
                    fetch(
                        API_END_POINT +
                            `getPlayers${gen}?player=${this.state.name}`
                    )
                        .then((res) => {
                            return res.json();
                        })
                        .then((player) => {
                            if (player !== undefined) {
                                for (
                                    let i = 0;
                                    i < player.players.length;
                                    ++i
                                ) {
                                    if (
                                        ValidateUrl(
                                            player.players[i].plname
                                        ) === ValidateUrl(this.state.name)
                                    ) {
                                        this.setState({
                                            playerData: player.players[i],
                                            isFromRoute: false,
                                        });
                                        break;
                                    }
                                }
                            }
                        })
                        .catch((err) => console.log(err));
                } else {
                    this.setState({
                        isFromRoute: true,
                    });
                }
            })
            .catch((err) => console.log(err));
        }
    }

    componentWillUnmount(){
        this._isMounted = false;
    }
    
    handleSeason(filed) {
        if(this._isMounted){
            if (this.state.isSeasonFlip === true) {
                let res = [];

                if (filed === "yearto") {
                    res = this.state.playerStats.sort((a, b) => {
                        if (
                            a["yearfrom"] !== null &&
                            a["yearfrom"] !== undefined &&
                            b["yearfrom"] !== null &&
                            b["yearfrom"] !== undefined
                        ) {
                            if (a["yearfrom"] < b["yearfrom"]) {
                                return -1;
                            }
                            if (a["yearfrom"] > b["yearfrom"]) {
                                return 1;
                            }
                        }
                        return 0;
                    });
                } else {
                    res = this.state.playerStats;
                }

                res = res.sort((a, b) => {
                    if (
                        a[filed] !== null &&
                        a[filed] !== undefined &&
                        b[filed] !== null &&
                        b[filed] !== undefined
                    ) {
                        if (a[filed] < b[filed]) {
                            return -1;
                        }
                        if (a[filed] > b[filed]) {
                            return 1;
                        }
                    }
                    return 0;
                });
                
                this.setState({
                    playerStats: res,
                    isSeasonFlip: false,
                });
            } else {
                let res = [];

                if (filed === "yearto") {
                    res = res = this.state.playerStats.sort((a, b) => {
                        if (
                            a["yearfrom"] !== null &&
                            a["yearfrom"] !== undefined &&
                            b["yearfrom"] !== null &&
                            b["yearfrom"] !== undefined
                        ) {
                            if (a["yearfrom"] > b["yearfrom"]) {
                                return -1;
                            }
                            if (a["yearfrom"] < b["yearfrom"]) {
                                return 1;
                            }
                        }
                        return 0;
                    });
                } else {
                    res = this.state.playerStats;
                }

                res = res.sort((a, b) => {
                    if (
                        a[filed] !== null &&
                        a[filed] !== undefined &&
                        b[filed] !== null &&
                        b[filed] !== undefined
                    ) {
                        if (a[filed] > b[filed]) {
                            return -1;
                        }
                        if (a[filed] < b[filed]) {
                            return 1;
                        }
                    }
                    return 0;
                });

                this.setState({
                    playerStats: res,
                    isSeasonFlip: true,
                });
            }
        }
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>{this.state.name} | BasketballStat.info</title>
                    <meta name="description" content={`${this.state.name} player statistics. The most comprehensive basketball database in the world. BasketballStat.info`} />
                </Helmet>

                {this.state.dataRecieved === false ? (
                    <LinearProgress style={{height:10}} color="secondary"></LinearProgress>
                ) : (
                    <div>
                        <div className="palyer-card-table">
                            {this.state.isFromRoute === true ? (
                                <div>
                                    <PlayerTable
                                        gender={this.state.gender}
                                        playerData={
                                            this.state.playerData.playerData
                                        }
                                    ></PlayerTable>
                                </div>
                            ) : (
                                <div>
                                    <PlayerTable
                                        gender={this.state.gender}
                                        playerData={this.state.playerData}
                                    ></PlayerTable>
                                </div>
                            )}
                            <div style={{ margin: 15 }}>
                                <SearchByPlayerNameForRes
                                    gender={this.state.gender}
                                ></SearchByPlayerNameForRes>
                            </div>
                        </div>
                        <div className="player-data-table">
                            <PlayerCareerTable
                                playerStats={this.state.playerStats}
                                gender={this.state.gender}
                                handleSeason={this.handleSeason}
                            ></PlayerCareerTable>
                        </div>
                    </div>
                )}
            </>
        );
    }
}
export default withRouter(PlayerCard);
