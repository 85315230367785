import React from "react";

import PersonIcon from "@material-ui/icons/Person";
import { ValidateTableTextInput } from "../../validation";
import Girl from "../../assetes/girl.png";
import { LinearProgress } from "@material-ui/core";

const PlayerTable = (props) => {
    const { playerData, gender } = props;

    return (
        <>
            {playerData === undefined ? (
                <LinearProgress style={{height:10}} color="secondary"></LinearProgress>
            ) : (
                <div style={{ width: "100%" }}>
                    <table className="table-stat">
                        <thead>
                            <tr>
                                <th align="center">
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderLeft: "0px solid white",
                                        }}
                                    >
                                        {gender === "male" ? (
                                            <PersonIcon></PersonIcon>
                                        ) : (
                                            <img
                                                src={Girl}
                                                alt="girl"
                                                style={{
                                                    height: 30,
                                                    marginRight: 3,
                                                }}
                                            ></img>
                                        )}
                                        Player's Name
                                    </div>
                                </th>
                                <th align="center">Position</th>
                                <th align="center">Height</th>
                                <th align="center">Weight</th>
                                <th align="center">Year</th>
                                <th align="center">Age</th>
                                <th align="center">Passport</th>
                                <th align="center">College</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td
                                    component="th"
                                    align="center"
                                    style={{
                                        borderLeft: "0px solid white",
                                        fontSize: "larger",
                                        fontWeight: "bold",
                                        color: "#040DBF",
                                    }}
                                >
                                    {playerData.plname || playerData.player}
                                </td>
                                <td align="center">
                                    {ValidateTableTextInput(
                                        playerData.position
                                    )}
                                </td>
                                <td align="center">
                                    {ValidateTableTextInput(playerData.height)}
                                </td>
                                <td align="center">
                                    {ValidateTableTextInput(playerData.weight)}
                                </td>
                                <td align="center">
                                    {ValidateTableTextInput(playerData.bdate)}
                                </td>
                                <td align="center">
                                    {ValidateTableTextInput(playerData.myAge)}
                                </td>
                                <td align="center">
                                    {ValidateTableTextInput(
                                        playerData.passport
                                    )}
                                </td>
                                <td align="center">
                                    {ValidateTableTextInput(playerData.college)}
                                </td>
                            </tr>
                            {playerData.plcomment === null ||
                            playerData.plcomment === -1 ||
                            playerData.plcomment === "-1" ||
                            playerData.plcomment === undefined ||
                            playerData.plcomment === "" ||
                            playerData.plcomment === "  " ? (
                                <></>
                            ) : (
                                <tr>
                                    <td colSpan="8">{playerData.plcomment}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
};
export default PlayerTable;
