import React from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import "../styles/Home.scss";

function Copyright() {
    return (
        <Typography className="copy-cc" style={{margin:10}} variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="https://azrielisoftware.com/">
                MeteorOps
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}
export default Copyright;
