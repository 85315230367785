import React from "react";

import { AppBar, Toolbar, makeStyles, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import "../styles/Home.scss";
import Logo from "../assetes/logo.png";
const useStyles = makeStyles(() => ({
    btns: {
        flex: 1,
    },
    btn: {
        color: "#fff",
        marginRight: "10px",
        textDecoration: "none",

        "&:hover": {
            color: "#61dafb",
        },
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ],
    },
    img: {
        height: 50,
    },
}));

const ToolBar = () => {
    const classes = useStyles();
    return (
        <div className="home">
            <AppBar position="static">
                <Toolbar>
                    <div className={classes.btns}>
                        <Link to="/" className={classes.btn}>
                            <Button className={classes.btn}>Home</Button>
                        </Link>
                        <Link to="/about" className={classes.btn}>
                            <Button className={classes.btn}>ABOUT US</Button>
                        </Link>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div>
                            <a
                                href="/"
                                style={{
                                    color: "#fff",
                                    textDecoration: "none",
                                }}
                            >
                                <h2>Basketballstat.info</h2>
                            </a>
                        </div>
                        <img
                            src={Logo}
                            alt="logo"
                            style={{ marginLeft: 10, height: 50 }}
                        />
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
};
export default ToolBar;
