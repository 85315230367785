import React, { useEffect, useRef, useState } from "react";

import PublicIcon from "@material-ui/icons/Public";
import { FetchData, ReturnToUnvalidateUrl } from "../../validation";

const TeamHeaderTable = (props) => {
    const [leagues, setLeagues] = useState([]);
    const [seasons, setSeasons] = useState([]);
    const isMounted = useRef(true);

    useEffect(() => {
        const fetchData = async () => {
            if(isMounted.current){
                let gen = "";
                if (props.gender !== "male") {
                    gen = "w";
                }
                let fromTo = props.year.split(/[\s-]+/);

                const ret_val = await FetchData(
                    `getLeagues${gen}?country=${props.country}&team=${props.team}&yearfrom= ${fromTo[0]}&yearto=${fromTo[1]}`
                );
                setLeagues(ret_val.leagues);
            }
        };

        const fetchSesons = async () => {
            if(isMounted.current){
                let gen = "";

                if (props.gender !== "male") {
                    gen = "w";
                }

                const ret_val = await FetchData(
                    `getYearsByCountryAndTeam${gen}?country=${props.country}&team=${props.team}`
                );

                let ses_res = [];
                ret_val.years.forEach((element) => {
                    ses_res.push(String(element.yearfrom + "-" + element.yearto));
                });

                setSeasons(ses_res);
            }
        };

        fetchData();
        fetchSesons();

        return () => {
            isMounted.current = false;
        };

    }, [props]);

    const handleChange = (event) => {
        props.handleChangeCallback(event.target.value);
    };

    const handleChangeSeosons = (event) => {
        props.handleSeasonChange(event.target.value);
    };

    return (
        <>
            <div style={{ width: "100%" }}>
                <table className="table-stat">
                    <thead>
                        <tr>
                            <th
                                align="center"
                                style={{ borderLeft: "#ccc 1px solid" }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <PublicIcon
                                        style={{ marginRight: 3 }}
                                    ></PublicIcon>
                                    Country
                                </div>
                            </th>
                            <th align="center">Team</th>
                            <th align="center">Season</th>
                            <th
                                align="center"
                                style={{ borderRight: "#ccc 1px solid" }}
                            >
                                league
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td
                                component="th"
                                align="center"
                                style={{ borderLeft: "#ccc 1px solid" }}
                            >
                                {ReturnToUnvalidateUrl(props.country)}
                            </td>
                            <td align="center">
                                {ReturnToUnvalidateUrl(props.team)}
                            </td>
                            <td align="center">
                                <div>
                                    <select
                                        style={{ minWidth: 150, height: 30 }}
                                        id="seasons"
                                        value={props.year}
                                        onChange={handleChangeSeosons}
                                    >
                                        {seasons === undefined ||
                                        seasons.length === 0 ? (
                                            <option value=""></option>
                                        ) : (
                                            seasons.map((element, index) => (
                                                <option
                                                    key={index}
                                                    value={element}
                                                >
                                                    {element}
                                                </option>
                                            ))
                                        )}
                                    </select>
                                </div>
                            </td>
                            <td
                                align="center"
                                style={{ borderRight: "#ccc 1px solid" }}
                            >
                                <div>
                                    <select
                                        style={{ minWidth: 150, height: 30 }}
                                        id="leagues"
                                        value={ReturnToUnvalidateUrl(
                                            props.league
                                        )}
                                        onChange={handleChange}
                                    >
                                        {leagues === undefined ||
                                        leagues.length === 0 ? (
                                            <option value=""></option>
                                        ) : (
                                            leagues.map((element, index) => (
                                                <option
                                                    key={index}
                                                    value={element}
                                                >
                                                    {element}
                                                </option>
                                            ))
                                        )}
                                    </select>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
};
export default TeamHeaderTable;
