import React from "react";

export default class Ad extends React.Component {
    componentDidMount() {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }

    render() {
        return (
            <div className="ad">
                {/* <ins
                    className="adsbygoogle"
                    style={{ display: "block" }}
                    data-ad-client="ca-pub-9382953357114912"
                    data-ad-slot=""
                    data-ad-format="auto"
                /> */}
            </div>
        );
    }
}
