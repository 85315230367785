import React from "react";
import { Link } from "react-router-dom";
import { ValidateUrl, ValidateTableTextInput } from "../../validation";
import "../../styles/TableStat.scss";

const PlayerSearchResultsTable = (props) => {
    const playersList = props.playersList;
    const { isDataReceived } = props;

    return (
        <React.Fragment>
            {playersList.length === 0 && isDataReceived === true ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Link to={"/"} style={{ color: "blue" }}>
                        <h3>No Players Found, Back </h3>
                    </Link>
                </div>
            ) : (
                <div style={{ width: "100%" }}>
                    <table className="table-stat" aria-label="sticky table">
                        <thead>
                            <tr>
                                <th align="center">Player's Name</th>
                                <th align="center">Position</th>
                                <th align="center">Hgt</th>
                                <th align="center">Wgt</th>
                                <th align="center">Year</th>
                                <th align="center">Age</th>
                                <th align="center">Passport</th>
                                <th align="center">College</th>
                                <th align="center">Comment</th>
                            </tr>
                        </thead>
                        <tbody className="regular">
                            {playersList.map((row, index) => (
                                <tr key={index}>
                                    <td
                                        component="th"
                                        align="left"
                                        style={{
                                            cursor: "pointer",
                                            textDecoration: "underline",
                                            color: "blue",
                                        }}
                                    >
                                        <Link
                                            to={`/players/${
                                                props.selectedGender
                                            }/${ValidateUrl(row.plname)}`}
                                            target="_blank"
                                        >
                                            {row.plname}
                                        </Link>
                                    </td>
                                    <td align="center">
                                        {row.position === null ? (
                                            <div>--</div>
                                        ) : (
                                            row.position
                                        )}
                                    </td>
                                    <td align="center">
                                        {row.height === null ||
                                        row.height === 0 ? (
                                            <div>--</div>
                                        ) : (
                                            row.height
                                        )}
                                    </td>
                                    <td align="center">
                                        {row.weight === null ||
                                        row.weight === 0 ? (
                                            <div>--</div>
                                        ) : (
                                            row.weight
                                        )}
                                    </td>
                                    <td align="center">
                                        {row.bdate === null ||
                                        row.bdate === 0 ? (
                                            <div>--</div>
                                        ) : (
                                            row.bdate
                                        )}
                                    </td>
                                    <td align="center">
                                        {row.myAge === null ||
                                        row.myAge === 0 ? (
                                            <div>--</div>
                                        ) : (
                                            row.myAge
                                        )}
                                    </td>
                                    <td align="left">
                                        {row.passport === null ? (
                                            <div></div>
                                        ) : (
                                            row.passport
                                        )}
                                    </td>
                                    <td align="left">
                                        {row.college === null ||
                                        row.college === -1 ||
                                        row.college === "-1" ||
                                        row.college === undefined ||
                                        row.college === "" ||
                                        row.college === 0 ? (
                                            <div></div>
                                        ) : (
                                            row.college
                                        )}
                                    </td>
                                    <td
                                        align="left"
                                        style={{
                                            maxWidth: 200,
                                        }}
                                    >
                                        {ValidateTableTextInput(row.plcomment)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </React.Fragment>
    );
};
export default PlayerSearchResultsTable;
