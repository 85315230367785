import React from "react";
import "../styles/Home.scss";

const About = () => {
    return (
        <>
            <div className="home">
                {/* <div className="banner">
                    <h2 style={{ marginLeft: 15 }}>
                        The Most Comprehensive Basketball Database In The World{" "}
                    </h2>
                </div> */}
                <h2
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    The Most Comprehensive Basketball Database In The World
                </h2>
                <div className="container">
                    <div style={{ marginTop: 20 }}>
                        <div className="about-text">
                            <h2>Welcome To BasketballStat.Info</h2>

                            <p>
                                This website features the most comprehensive
                                basketball statistics database in the world. The
                                database contains detailed statistics of players
                                through their entire career along with some
                                personal details (Age, Height, etc). Statistics
                                in this website stretches back to season 1996-97
                                for most of the leagues / countries. Some of the
                                countries have statistics from even before 1996,
                                like USA-NBA, USA-CBA, Spain, France, Italy,
                                Israel and Sweden.
                            </p>
                            <span>
                                Player's statistics can be searched using:
                            </span>
                            <span>
                                "Search by Player Name" Or using one of the
                                indexed search options:
                            </span>
                            <span>Country > Team > Year > League</span>
                            <span>Year > Country > Team > League</span>

                            <h2>Contact Us</h2>

                            <span>Email: vites@inter.net.il</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default About;
