import React from "react";

import { Link } from "react-router-dom";
import {
    ValidateTableNumericInput,
    ValidateUrl,
    ValidateTableTextInput,
} from "../../validation";
import Tooltip from "@material-ui/core/Tooltip";
import "../../styles/TableStat.scss";

const TeamTable = (props) => {
    const { handleSeason } = props;

    const handleSeasonClick = (filed) => {
        handleSeason(filed);
    };

    return (
        <>
            {props.stats === undefined ? (
                <div>loading...</div>
            ) : (
                <div style={{ width: "100%" }}>
                    <table className="table-stat">
                        <thead className="career-head">
                            <tr>
                                <th
                                    onClick={() => handleSeasonClick("player")}
                                    style={{
                                        textAlign: "center",
                                        paddingLeft: 8,
                                        borderLeft: "0px solid white",
                                    }}
                                >
                                    Player
                                </th>
                                <Tooltip title="Position" placement="top">
                                    <th
                                        align="center"
                                        onClick={() =>
                                            handleSeasonClick("position")
                                        }
                                    >
                                        Pos
                                    </th>
                                </Tooltip>
                                <Tooltip title="Height" placement="top">
                                    <th
                                        align="center"
                                        onClick={() =>
                                            handleSeasonClick("height")
                                        }
                                    >
                                        Hgt
                                    </th>
                                </Tooltip>
                                <Tooltip title="Weight" placement="top">
                                    <th
                                        align="center"
                                        onClick={() =>
                                            handleSeasonClick("weight")
                                        }
                                    >
                                        Wgt
                                    </th>
                                </Tooltip>
                                <th
                                    align="center"
                                    onClick={() => handleSeasonClick("bdate")}
                                >
                                    Year
                                </th>
                                <th
                                    align="center"
                                    onClick={() => handleSeasonClick("myAge")}
                                >
                                    Age
                                </th>
                                <th
                                    align="center"
                                    onClick={() =>
                                        handleSeasonClick("passport")
                                    }
                                >
                                    Passport
                                </th>
                                <th
                                    align="center"
                                    onClick={() => handleSeasonClick("comment")}
                                >
                                    League
                                </th>
                                <Tooltip title="Games" placement="top">
                                    <th
                                        align="center"
                                        onClick={() => handleSeasonClick("gm")}
                                    >
                                        Gm
                                    </th>
                                </Tooltip>
                                <Tooltip title="Minutes" placement="top">
                                    <th
                                        align="center"
                                        style={{ backgroundColor: "#FFC2FF" }}
                                        onClick={() => handleSeasonClick("min")}
                                    >
                                        Min
                                    </th>
                                </Tooltip>
                                <Tooltip title="Points" placement="top">
                                    <th
                                        align="center"
                                        style={{ background: "#FFDB99" }}
                                        onClick={() => handleSeasonClick("pts")}
                                    >
                                        Pts
                                    </th>
                                </Tooltip>
                                <Tooltip title="1 Point Made" placement="top">
                                    <th
                                        align="center"
                                        onClick={() => handleSeasonClick("1PM")}
                                    >
                                        1PM
                                    </th>
                                </Tooltip>
                                <Tooltip
                                    title="1 Point Attempt"
                                    placement="top"
                                >
                                    <th
                                        align="center"
                                        onClick={() => handleSeasonClick("1PA")}
                                    >
                                        1PA
                                    </th>
                                </Tooltip>
                                <Tooltip
                                    title="1 Point Percentage"
                                    placement="top"
                                >
                                    <th
                                        align="center"
                                        style={{ backgroundColor: "#FFFF99" }}
                                        onClick={() => handleSeasonClick("1P%")}
                                    >
                                        1P%
                                    </th>
                                </Tooltip>
                                <Tooltip title="2 Point Made" placement="top">
                                    <th
                                        align="center"
                                        onClick={() => handleSeasonClick("2PM")}
                                    >
                                        2PM
                                    </th>
                                </Tooltip>
                                <Tooltip
                                    title="2 Point Attempt"
                                    placement="top"
                                >
                                    <th
                                        align="center"
                                        onClick={() => handleSeasonClick("2PA")}
                                    >
                                        2PA
                                    </th>
                                </Tooltip>
                                <Tooltip
                                    title="2 Point Percentage"
                                    placement="top"
                                >
                                    <th
                                        align="center"
                                        style={{ background: "#99FFCC" }}
                                        onClick={() => handleSeasonClick("2P%")}
                                    >
                                        2P%
                                    </th>
                                </Tooltip>
                                <Tooltip title="3 Point Made" placement="top">
                                    <th
                                        align="center"
                                        onClick={() => handleSeasonClick("3PM")}
                                    >
                                        3PM
                                    </th>
                                </Tooltip>
                                <Tooltip
                                    title="3 Point Attempt"
                                    placement="top"
                                >
                                    <th
                                        align="center"
                                        onClick={() => handleSeasonClick("3PA")}
                                    >
                                        3PA
                                    </th>
                                </Tooltip>
                                <Tooltip
                                    title="3 Point Percentage"
                                    placement="top"
                                >
                                    <th
                                        align="center"
                                        style={{ background: "#9FFFFF" }}
                                        onClick={() => handleSeasonClick("3P%")}
                                    >
                                        3P%
                                    </th>
                                </Tooltip>
                                <Tooltip
                                    title="Rebounds - Defense"
                                    placement="top"
                                >
                                    <th
                                        align="center"
                                        onClick={() => handleSeasonClick("rd")}
                                    >
                                        RD
                                    </th>
                                </Tooltip>
                                <Tooltip
                                    title="Rebounds - Offense"
                                    placement="top"
                                >
                                    <th
                                        align="center"
                                        onClick={() => handleSeasonClick("ro")}
                                    >
                                        RO
                                    </th>
                                </Tooltip>
                                <Tooltip
                                    title="Rebounds - Total"
                                    placement="top"
                                >
                                    <th
                                        align="center"
                                        style={{ background: "#93CCFF" }}
                                        onClick={() => handleSeasonClick("rt")}
                                    >
                                        RT
                                    </th>
                                </Tooltip>

                                <Tooltip title="Assists" placement="top">
                                    <th
                                        align="center"
                                        onClick={() => handleSeasonClick("ast")}
                                    >
                                        Ast
                                    </th>
                                </Tooltip>
                                <Tooltip title="Turn Overs" placement="top">
                                    <th
                                        align="center"
                                        style={{ background: "#CCB5FF" }}
                                        onClick={() => handleSeasonClick("TO")}
                                    >
                                        To
                                    </th>
                                </Tooltip>
                                <Tooltip title="Steals" placement="top">
                                    <th
                                        align="center"
                                        onClick={() => handleSeasonClick("st")}
                                    >
                                        St
                                    </th>
                                </Tooltip>
                                <Tooltip
                                    title="Block Shots Made"
                                    placement="top"
                                >
                                    <th
                                        align="center"
                                        onClick={() => handleSeasonClick("bl")}
                                    >
                                        BL
                                    </th>
                                </Tooltip>
                                <Tooltip
                                    title="Block Shots on Player"
                                    placement="top"
                                >
                                    <th
                                        align="center"
                                        onClick={() => handleSeasonClick("bo")}
                                    >
                                        BO
                                    </th>
                                </Tooltip>
                                <Tooltip title="Personal Foul" placement="top">
                                    <th
                                        align="center"
                                        onClick={() => handleSeasonClick("pf")}
                                    >
                                        PF
                                    </th>
                                </Tooltip>
                                <Tooltip title="Foul on Player" placement="top">
                                    <th
                                        align="center"
                                        onClick={() => handleSeasonClick("fo")}
                                    >
                                        FO
                                    </th>
                                </Tooltip>
                                <Tooltip title="Dunk" placement="top">
                                    <th
                                        align="center"
                                        onClick={() => handleSeasonClick("du")}
                                    >
                                        DU
                                    </th>
                                </Tooltip>
                                <Tooltip
                                    title="Highest Score During Season"
                                    placement="top"
                                >
                                    <th
                                        align="center"
                                        onClick={() => handleSeasonClick("hi")}
                                    >
                                        Hi
                                    </th>
                                </Tooltip>
                                <Tooltip
                                    title="Number on Shirt-Jersey"
                                    placement="top"
                                >
                                    <th
                                        align="center"
                                        onClick={() => handleSeasonClick("ns")}
                                    >
                                        Ns
                                    </th>
                                </Tooltip>
                            </tr>
                        </thead>
                        <tbody className="regular">
                            {props.stats.map((row, index) => (
                                <tr key={index}>
                                    <td
                                        component="th"
                                        align="left"
                                        style={{
                                            cursor: "pointer",
                                            textDecoration: "underline",
                                            color: "blue",
                                            borderLeft: "0px solid white",
                                        }}
                                    >
                                        {
                                            <Link
                                                to={`/players/${
                                                    props.gender
                                                }/${ValidateUrl(row.player)}`}
                                            >
                                                {row.player}
                                            </Link>
                                        }
                                    </td>
                                    <td align="center">
                                        {ValidateTableTextInput(row.position)}
                                    </td>
                                    <td align="center">
                                        {ValidateTableTextInput(row.height)}
                                    </td>
                                    <td align="center">
                                        {ValidateTableTextInput(row.weight)}
                                    </td>
                                    <td align="center">
                                        {ValidateTableTextInput(row.bdate)}
                                    </td>
                                    <td align="center">
                                        {ValidateTableTextInput(row.myAge)}
                                    </td>
                                    <td align="left">
                                        {ValidateTableTextInput(row.passport)}
                                    </td>
                                    <td align="left">
                                        {ValidateTableTextInput(row.comment)}
                                    </td>
                                    <td align="right">
                                        {row.gm === null ||
                                        row.gm === -1 ||
                                        row.gm === "-1" ||
                                        row.gm === undefined ||
                                        row.gm === ""
                                            ? "--"
                                            : row.gm.toFixed(0)}
                                    </td>
                                    <td
                                        align="right"
                                        style={{ background: "#FFC2FF" }}
                                    >
                                        {ValidateTableNumericInput(row.min)}
                                    </td>
                                    <td
                                        align="right"
                                        style={{ background: "#FFDB99" }}
                                    >
                                        {ValidateTableNumericInput(row.pts)}
                                    </td>
                                    <td align="right">
                                        {ValidateTableNumericInput(row["1PM"])}
                                    </td>
                                    <td align="right">
                                        {ValidateTableNumericInput(row["1PA"])}
                                    </td>
                                    <td
                                        align="right"
                                        style={{ background: "#FFFF99" }}
                                    >
                                        {ValidateTableNumericInput(row["1P%"])}
                                    </td>
                                    <td align="right">
                                        {ValidateTableNumericInput(row["2PM"])}
                                    </td>
                                    <td align="right">
                                        {ValidateTableNumericInput(row["2PA"])}
                                    </td>
                                    <td
                                        align="right"
                                        style={{ background: "#99FFCC" }}
                                    >
                                        {ValidateTableNumericInput(row["2P%"])}
                                    </td>
                                    <td align="right">
                                        {ValidateTableNumericInput(row["3PM"])}
                                    </td>
                                    <td align="right">
                                        {ValidateTableNumericInput(row["3PA"])}
                                    </td>
                                    <td
                                        align="right"
                                        style={{ background: "#9FFFFF" }}
                                    >
                                        {ValidateTableNumericInput(row["3P%"])}
                                    </td>
                                    <td align="right">
                                        {ValidateTableNumericInput(row.rd)}
                                    </td>
                                    <td align="right">
                                        {ValidateTableNumericInput(row.ro)}
                                    </td>
                                    <td
                                        align="right"
                                        style={{ background: "#93CCFF" }}
                                    >
                                        {ValidateTableNumericInput(row.rt)}
                                    </td>

                                    <td align="right">
                                        {ValidateTableNumericInput(row.ast)}
                                    </td>
                                    <td
                                        align="right"
                                        style={{ background: "#CCB5FF" }}
                                    >
                                        {ValidateTableNumericInput(row.TO)}
                                    </td>
                                    <td align="centrighter">
                                        {ValidateTableNumericInput(row.st)}
                                    </td>
                                    <td align="right">
                                        {ValidateTableNumericInput(row.bl)}
                                    </td>
                                    <td align="right">
                                        {ValidateTableNumericInput(row.bo)}
                                    </td>
                                    <td align="right">
                                        {ValidateTableNumericInput(row.pf)}
                                    </td>
                                    <td align="right">
                                        {ValidateTableNumericInput(row.fo)}
                                    </td>
                                    <td align="right">
                                        {ValidateTableNumericInput(row.du)}
                                    </td>
                                    <td align="right">
                                        {row.hi === null ||
                                        row.hi === -1 ||
                                        row.hi === "-1" ||
                                        row.hi === undefined ||
                                        row.hi === ""
                                            ? "--"
                                            : row.hi.toFixed(0)}
                                    </td>
                                    <td align="right">
                                        {row.ns === null ||
                                        row.ns === -1 ||
                                        row.ns === "-1" ||
                                        row.ns === undefined ||
                                        row.ns === ""
                                            ? "--"
                                            : row.ns.toFixed(0)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
};
export default TeamTable;
