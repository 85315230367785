import React from "react";
import { withRouter } from "react-router-dom";
import "../../styles/PlayerCard.scss";
import TeamTable from "./TeamTable";
import TeamHeaderTable from "./TeamHeaderTable";
import { API_END_POINT } from "../../validation";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Helmet } from "react-helmet";

class TeamCard extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.handleSort = this.handleSort.bind(this);

        this.state = {
            gender: this.props.match.params.gender,
            team: this.props.match.params.team,
            country: this.props.match.params.country,
            league: this.props.match.params.league,
            year: this.props.match.params.year,
            stats: [],
            dataReceived: false,
            isSeasonFlip: true,
        };
    }

    handleLeagueChange = (league) => {
        if(this._isMounted){

            if (league === "") {
                return;
            }

            this.setState({
                league: league,
                dataReceived:false
            });

            let fromTo = this.state.year.split(/[\s-]+/);
            let gen = "";

            if (this.state.gender !== "male") {
                gen = "w";
            }

            let leagueSelected = league.replace("+", "%2B");

            fetch(
                API_END_POINT +
                    `getStats${gen}?country=` +
                    `${this.state.country}&team=${this.state.team}&yearfrom=${fromTo[0]}&yearto=${fromTo[1]}&league=${leagueSelected}`
            )
                .then((res) => {
                    return res.json();
                })
                .then((stats) => {
                        this.setState({ stats: stats.stats, dataReceived: true });
                    
                })
                .catch((err) => console.log(err));
        }
    };

    componentWillUnmount(){
        this._isMounted = false;
    }

    handleSeasonChange = (year) => {
        if(this._isMounted){

            if (year === "") {
                return;
            }

            this.setState({
                year: year,
                dataReceived:false
            });

            let fromTo = year.split(/[\s-]+/);
            let gen = "";
            if (this.state.gender !== "male") {
                gen = "w";
            }

            let leagueSelected = "All";
            this.setState({
                league: leagueSelected,
            });
            fetch(
                API_END_POINT +
                    `getStats${gen}?country=` +
                    `${this.state.country}&team=${this.state.team}&yearfrom=${fromTo[0]}&yearto=${fromTo[1]}&league=${leagueSelected}`
            )
                .then((res) => {
                    return res.json();
                })
                .then((stats) => {
                    this.setState({ stats: stats.stats, dataReceived: true });
                })
                .catch((err) => console.log(err));
        }
    };

    componentDidMount() {
        if(!this._isMounted){
            this._isMounted = true;

            let fromTo = this.state.year.split(/[\s-]+/);
            let gen = "";
            if (this.state.gender !== "male") {
                gen = "w";
            }

            let league = this.state.league.replace("+", "%2B");

            fetch(
                API_END_POINT +
                    `getStats${gen}?country=` +
                    `${this.state.country}&team=${this.state.team}&yearfrom=${fromTo[0]}&yearto=${fromTo[1]}&league=${league}`
            )
                .then((res) => {
                    return res.json();
                })
                .then((stats) => {
                    this.setState({ stats: stats.stats, dataReceived: true });
                })
                .catch((err) => console.log(err));
        }
    }

    handleSort(filed) {
        if (this.state.isSeasonFlip === false) {
            let res = this.state.stats.sort((a, b) => {
                if (
                    a[filed] !== null &&
                    a[filed] !== undefined &&
                    b[filed] !== null &&
                    b[filed] !== undefined
                ) {
                    if (a[filed] < b[filed]) {
                        return -1;
                    }
                    if (a[filed] > b[filed]) {
                        return 1;
                    }
                }

                return 0;
            });

            this.setState({
                stats: res,
                isSeasonFlip: true,
            });
        } else {
            let res = this.state.stats.sort((a, b) => {
                if (
                    a[filed] !== null &&
                    a[filed] !== undefined &&
                    b[filed] !== null &&
                    b[filed] !== undefined
                ) {
                    if (a[filed] > b[filed]) {
                        return -1;
                    }
                    if (a[filed] < b[filed]) {
                        return 1;
                    }
                }
                return 0;
            });
            this.setState({
                stats: res,
                isSeasonFlip: false,
            });
        }
    }

    render() {
        return (
            <>
                {this.state.dataReceived === false ? (
                    <LinearProgress style={{height:10}} color="secondary"></LinearProgress>
                ) : (
                    <div>
                        <Helmet>
                            <title>{this.state.country + "-" + this.state.team}</title>
                            <meta name="description" content="basketball team" />
                            <meta name="keywords" cpntent="basketball,stats,team,Statistics,PTS,REB,AST" />
                        </Helmet>
                        <div
                            style={{
                                marginTop: 15,
                                width: 600,
                                marginLeft: 10,
                            }}
                        >
                            <TeamHeaderTable
                                handleSeasonChange={this.handleSeasonChange}
                                handleChangeCallback={this.handleLeagueChange}
                                gender={this.state.gender}
                                team={this.state.team}
                                country={this.state.country}
                                league={this.state.league}
                                year={this.state.year}
                            ></TeamHeaderTable>
                        </div>

                        <div className="player-data-table">
                            <TeamTable
                                stats={this.state.stats}
                                gender={this.state.gender}
                                handleSeason={this.handleSort}
                            ></TeamTable>
                        </div>
                    </div>
                )}
            </>
        );
    }
}
export default withRouter(TeamCard);
