import React from "react";
import { Grid } from "@material-ui/core";
import { HashRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./Components/Home";

import PlayerSearchResults from "./Components/search_results_components/PlayerSearchResults";
import PlayerCard from "./Components/search_results_components/PlayerCard";
import TeamCard from "./Components/search_results_components/TeamCard";
import Copyright from "./Components/Copyright";
import ToolBar from "./Components/ToolBar";
import PageDoesntExist from "./Components/PageDoesntExist";

import "./App.scss";
import About from "./Components/About";

import { Helmet } from 'react-helmet'


class App extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Basketballstat.info</title>
                    <meta name="description" content="The Most Comprehensive Basketball Database In The World. Men Statistics. Women Statistics. Team Statistics." />
                </Helmet>
                <Grid container direction="column" style={{ minWidth: 1400 }}>
                    <div className="app-content">
                        <Router>
                            <Grid item>
                                <ToolBar />
                            </Grid>
                            <Grid item>
                                <div className="main-content">
                                    <Switch>
                                        <Route exact path="/">
                                            <Home />
                                        </Route>
                                        <Route exact path="/about">
                                            <About />
                                        </Route>
                                        {/* <Route exact path="/contactUs">
                                            <ContactUs />
                                        </Route> */}
                                        <Route
                                            exact
                                            path="/player-search/:gender/:name"
                                            render={() => <PlayerSearchResults key={Math.random()} />}
                                        />
                                        <Route
                                            exact
                                            path="/players/:gender/:name"
                                        >
                                            <PlayerCard />
                                        </Route>
                                        <Route exact path="/pagedoesntexist">
                                            <PageDoesntExist />
                                        </Route>
                                        <Route
                                            exact
                                            path="/teams/:gender/:country/:team/:year/:league"
                                        >
                                            <TeamCard />
                                        </Route>
                                    </Switch>
                                </div>
                            </Grid>
                        </Router>
                    </div>
                    <Grid item>
                        <footer style={{ paddingTop: "15px" }}>
                            <Copyright />
                        </footer>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export default App;
