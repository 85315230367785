import React from "react";
import Icon from "../../assetes/player.png";
import IconWorld from "../../assetes/world.png";
import Cale from "../../assetes/calendar.png";

export const Player = () => {
    return (
        <>
            <img src={Icon} alt="player" style={{ height: 50 }} />
        </>
    );
};

export const World = () => {
    return (
        <>
            <img src={IconWorld} alt="IconWorld" style={{ height: 50 }} />
        </>
    );
};

export const Calendar = () => {
    return (
        <>
            <img src={Cale} alt="Calendar" style={{ height: 50 }} />
        </>
    );
};
