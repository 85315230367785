import React from "react";
import "../../styles/SearchByCountry.scss";
import SelectCategory from "./SelectCategory";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import SelectedYear from "./SelectedYear";
import { API_END_POINT, ValidateUrl } from "../../validation";
import LinearProgress from "@material-ui/core/LinearProgress";

const mapStateToProps = (state) => {
    return {
        gender: state.gender,
    };
};

class SearchByYear extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleTeamChange = this.handleTeamChange.bind(this);
        this.handleYearChange = this.handleYearChange.bind(this);
        this.handleLeagueChange = this.handleLeagueChange.bind(this);

        this.state = {
            year: "",
            country: "",
            team: "",
            yearFrom: "",
            yearTo: "",
            league: "",
            years: [],
            countries: [],
            teams: [],
            leagues: [],
            validTeam: "",
            validLeage: "",
            dataReceived: false,
        };
    }

    componentDidMount() {
        if (!this._isMounted) {
            this._isMounted = true;

            let gen = "";
            if (this.props.gender !== "male") {
                gen = "w";
            }

            fetch(API_END_POINT + `getAllYears${gen}`)
                .then((res) => {
                    return res.json();
                })
                .then((years) => {
                    this.setState({ years: years.years, dataReceived: true });
                })
                .catch((err) => console.log("error: " + err));
        }
    }

    componentWillUnmount(){
        this._isMounted = false;
    }
    
    handleYearChange = (year) => {
        if(this._isMounted){
            if (year !== "") {
                let gen = "";
                if (this.props.gender !== "male") {
                    gen = "w";
                }
                
                let fromTo = year.split(/[\s-]+/);
                
                this.setState({
                    year: year,
                    countries: [],
                    teams: [],
                    leagues: [],
                country: "",
                team: "",
                league: "",
                yearFrom: fromTo[0],
                yearTo: fromTo[1],
                validTeam: "",
                validLeage: "",
            });
            fetch(
                API_END_POINT +
                    `getCountriesByYears${gen}?yearfrom=` +
                    `${fromTo[0]}&yearto=${fromTo[1]}`
                    )
                    .then((res) => {
                        return res.json();
                    })
                    .then((countries) => {
                        this.setState({ countries: countries.countries });
                    })
                    .catch((err) => console.log("error: " + err));
            } else {
                this.setState({
                    year: "",
                    country: "",
                    team: "",
                    league: "",
                    yearFrom: "",
                    yearTo: "",
                    countries: [],
                    teams: [],
                    leagues: [],
                    validTeam: "",
                    validLeage: "",
                });
            }
        }
    };

    handleCountryChange = (country) => {
        if(this._isMounted){
            this.setState({
                country: country,
                leagues: [],
                teams: [],
                league: "",
                team: "",
                validTeam: "",
                validLeage: "",
            });
            let gen = "";
            if (this.props.gender !== "male") {
                gen = "w";
            }
            fetch(
                API_END_POINT +
                    `getTeamsByYearsAndCountry${gen}?yearfrom=` +
                    `${this.state.yearFrom}&yearto=${this.state.yearTo}&country=${country}`
            )
                .then((res) => {
                    return res.json();
                })
                .then((teams) => {
                    this.setState({ teams: teams.teams.sort() });
                })
                .catch((err) => console.log("error: " + err));
        }
    };

    handleTeamChange = (team) => {
        if(this._isMounted)
        {
            let valid = ValidateUrl(team);

            this.setState({
                team: team,
                leagues: [],
                league: "",
                validTeam: valid,
            });
            let gen = "";
            if (this.props.gender !== "male") {
                gen = "w";
            }
            fetch(
                API_END_POINT +
                    `getLeagues${gen}?country=` +
                    `${this.state.country}&team=${valid}&yearfrom= ${this.state.yearFrom}&yearto=${this.state.yearTo}`
            )
                .then((res) => {
                    return res.json();
                })
                .then((leagues) => {
                    this.setState({ leagues: leagues.leagues });
                })
                .catch((err) => console.log("error: " + err));
        }
    };

    handleLeagueChange = (league) => {
        if(this._isMounted)
        {
            let valid = ValidateUrl(league);
            
            this.setState({
                league: league,
                validLeage: valid,
            });
        }
    };

    componentDidUpdate(prevProps) {
        if(this._isMounted)
        {
            if (prevProps.gender !== this.props.gender) {
                let gen = "";

                if (this.props.gender !== "male") {
                    gen = "w";
                }
                fetch(API_END_POINT + `getAllYears${gen}`)
                    .then((res) => {
                        return res.json();
                    })
                    .then((years) => {
                        this.setState({
                            years: years.years,
                            year: "",
                            country: "",
                            team: "",
                            yearFrom: "",
                            yearTo: "",
                            league: "",
                            countries: [],
                            teams: [],
                            leagues: [],
                            validTeam: "",
                            validLeage: "",
                        });
                    })
                    .catch((err) => console.log("error: " + err));
            }
        }
    }

    render() {
        const { gender } = this.props;
        return (
            <>
                {this.setState.dataReceived === false ? (
                    <LinearProgress style={{height:10}} color="secondary"></LinearProgress>
                ) : (
                    <div>
                        <div className="search-by-country">
                            <div className="search-label">
                                Team Search By Year
                            </div>
                            <div className="select-categories">
                                <div className="select-category">
                                    <SelectedYear
                                        handleChangeCallback={
                                            this.handleYearChange
                                        }
                                        list={this.state.years}
                                        val={this.state.year}
                                    ></SelectedYear>
                                </div>
                                <div className="select-category">
                                    <SelectCategory
                                        disabled={this.state.countries.length}
                                        name="Country"
                                        handleChangeCallback={
                                            this.handleCountryChange
                                        }
                                        list={this.state.countries}
                                        val={this.state.country}
                                    ></SelectCategory>
                                </div>
                                <div className="select-category">
                                    <SelectCategory
                                        disabled={this.state.teams.length}
                                        name="Team"
                                        handleChangeCallback={
                                            this.handleTeamChange
                                        }
                                        list={this.state.teams}
                                        val={this.state.team}
                                    ></SelectCategory>
                                </div>
                                <div className="select-category">
                                    <SelectCategory
                                        disabled={this.state.leagues.length}
                                        name="League"
                                        handleChangeCallback={
                                            this.handleLeagueChange
                                        }
                                        list={this.state.leagues}
                                        val={this.state.league}
                                    ></SelectCategory>
                                </div>
                            </div>
                        </div>
                        <div className="search-button">
                            {!this.state.league ? (
                                <Link
                                    to="/"
                                    onClick={(event) => event.preventDefault()}
                                ></Link>
                            ) : (
                                <Link
                                    target="_blank"
                                    to={`/teams/${gender}/${this.state.country}/${this.state.validTeam}/${this.state.year}/${this.state.validLeage}/`}
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button variant="contained" color="primary">
                                        <SearchIcon></SearchIcon>Search
                                    </Button>
                                </Link>
                            )}
                        </div>
                    </div>
                )}
            </>
        );
    }
}
export default connect(mapStateToProps)(withRouter(SearchByYear));
