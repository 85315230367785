import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import SearchByPlayerName from "./SearchByPlayerName";
import SearchByCountry from "./SearchByCountry";
import SearchByYear from "./SearchByYear";

import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { Player, World, Calendar } from "./Player";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        "aria-controls": `scrollable-force-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        backgroundColor: theme.palette.background.paper,
    },
    btn: {
        color: "#fff",
        textDecoration: "none",
        marginTop: 100,
        "&:hover": {
            color: "#61dafb",
        },
    },
}));

const SearchTabControl = (props) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    scrollButtons="on"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="scrollable force tabs example"
                >
                    <Tab
                        label="Player Search By Name"
                        icon={<Player />}
                        {...a11yProps(0)}
                    />
                    <Tab
                        label="Team Search By Country"
                        icon={<World />}
                        {...a11yProps(1)}
                    />
                    <Tab
                        label="Team Search By Year"
                        icon={<Calendar />}
                        {...a11yProps(2)}
                    />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <SearchByPlayerName
                    gender={props.playerGender}
                ></SearchByPlayerName>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <SearchByCountry
                    gender={props.playerGender}
                    genderCallback={props.genderCallback}
                ></SearchByCountry>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <SearchByYear></SearchByYear>
            </TabPanel>
        </div>
    );
};
export default SearchTabControl;
